.footer {
	position: relative;
	padding-top: 272px;
	padding-bottom: 10px;
	background-color: $c-orange;
	z-index: 1;

	@include tablet {
		
	}
	
	@include mobile {
		// padding-top: 287px;
		padding-top: 76.533vw;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 350px;
		background-color: #5daffc;
		z-index: -1;

		@include tablet {
			
		}
		
		@include mobile {
			height: 80vw;
		}
	}

	&__container {
	}

	&__social {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-bottom: 650px;
		margin-bottom: 45.13vw;
		z-index: 1;

		@include tablet {
			
		}
		
		@include mobile {
			// margin-bottom: 350px;
			margin-bottom: 92vw;
		}
	}

	&__text {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		z-index: -1;
		user-select: none;
		display: flex;
		overflow: hidden;

		@include tablet {
			
		}
		
		@include mobile {

			bottom: 36vw;
			top: unset;
		}

		.title {
			width: 100%;
			flex-shrink: 0;
			color: $c-yellow;
		}
	}

	&__social-item {
		width: 160px;
		height: 160px;
		position: relative;
		border: 3px solid $c-yellow;
		background-color: $c-orange;

		@include tablet {
			width: 80px;
			height: 80px;
		}
		
		@include mobile {
			
		}


		&+& {
			margin-left: 128px;

			@include tablet {
				
			}
			
			@include mobile {
				margin-left: 48px;
			}
		}
	}

	&__social-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 90%;

		@include tablet {
			
		}
		
		@include mobile {
			max-width: 54px;
		}
	}

	&__menu {
		position: relative;
		z-index: 1;

		
	}

	&__nav {
		
	}

	&__nav-list {
		@include row;
	}

	&__nav-item {
		@include col(2);

		margin-bottom: 40px;

		@include tablet {
			@include col(4);
		}
		
		@include mobile {
			margin-bottom: 24px;
		}
	}

	&__nav-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 56px;
		border: 2px solid currentColor;
		text-align: center;
		font-size: 24px;
		line-height: 1;
		font-weight: bold;
		text-transform: uppercase;
		white-space: nowrap;
		color: $c-orange;

		@media (max-width: 1300px) {
			font-size: 18px;
		}

		@include tablet {
			
		}
		
		@include mobile {
			height: 36px;
			font-size: 16px;
		}

		span {
			transition: transform .5s cubic-bezier(0.34, 1.56, 0.64, 1);
		}

		&:hover {
			span {
				transform: scale(1.1);
			}
		}
		
	}

	&__line {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: auto;
		user-select: none;
		transform: translateY(-50%);
		z-index: 1;
		image-rendering: pixelated;

		@include tablet {
			
		}
		
		@include mobile {
			width: auto;
			height: 72px;
		}
	}

	&__water {
		position: absolute;
		// top: 60px;
		top: 4.16vw;
		left: 0;
		display: block;
		width: 17.7%;
		user-select: none;
		z-index: 3;
		pointer-events: none;

		@include tablet {
			
		}
		
		@include mobile {
			top: 195px;
			width: 33.86vw;
		}
	}
	&__trees {
		position: absolute;
		// top: 60px;
		// top: 4.16vw;
		left: 0;
		display: block;
		width: 100%;
		user-select: none;
		z-index: 2;
		pointer-events: none;
		image-rendering: pixelated;
		// bottom: 360px;
		bottom: 22vw;

		@media (max-width: 1250px) {
			bottom: 28vw;
		}

		@include tablet {
			bottom: 33vw;
		}
		
		@include mobile {
			display: none;
		}

		&--mobile {
			display: none;

			@include tablet {
				
			}
			
			@include mobile {
				display: block;
				width: 100%;
				bottom: 58vw;
				bottom: calc(58vw - 30vw * 0.3);
				transform: translateY(calc(-50% + 10vw));
			}
			@media (max-width: 360px) {
				bottom: calc(65vw - 30vw * 0.3);
			}
		}
	}
	&__ground {
		position: absolute;
		// top: 60px;
		// top: 4.16vw;
		left: 0;
		display: block;
		width: 100%;
		user-select: none;
		z-index: 2;
		pointer-events: none;
		image-rendering: pixelated;
		bottom: 240px;

		@include tablet {
			
		}
		
		@include mobile {
			width: auto;
			height: 92px;
			bottom: 60vw;
			transform: translateY(-50%);
		}
	}
	&__creature {
		position: absolute;
		// top: 60px;
		// top: 4.16vw;
		// left: 0;
		display: block;
		// width: 100%;
		user-select: none;
		z-index: 3;
		pointer-events: none;
		// image-rendering: pixelated;
		bottom: 220px;
		left: 37%;

		@include tablet {
			
		}
		
		@include mobile {
			width: 15vw;
			bottom: 69.8vw;
			left: 24%;
			transform: translateY(-50%);
		}
	}
}
