%btn {
	display: inline-block;
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
}

button,
a,
[class *= "button"],
[class *= "btn"],
[class *= "link"],
[type *= "submit"] {
	cursor: pointer;
}
