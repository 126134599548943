%container {
	width: 100%;
	max-width: calc(100% - 96px);
	margin: 0 auto;
	
	@include tablet {
		max-width: calc(100% - 48px);
	}

	@include mobile {
		max-width: calc(100% - 48px);
	}
}

.wrapper {

}

.container {
	@extend %container;
}

.row {
	@include row();
}
