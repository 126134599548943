.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	user-select: none;
	padding-top: 48px;

	@include tablet {
		
	}
	
	@include mobile {
		padding-top: 24px;
	}

	&__container {
	}

	&__grid {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__logo {
		display: none;

		@include mobile {
			display: block;
			width: 196px;
			user-select: none;
		}
	}

	&__logo-img {
		width: 100%;
		display: block;
	}

	&__burger {
		position: relative;
		margin-left: auto;
		// pointer-events: none;
		cursor: default;
	}
}
.burger {
	width: 76px;
	user-select: none;

	// cursor: pointer;
	transition: all .3s;
	// cursor: default;
	pointer-events: none;

	@include tablet {
		
	}
	
	@include mobile {
		width: 38px;
	}

	&:hover {
		transform: rotate(-7deg);
		transform-origin: 55% 20%;
	}



	&__icon {
		width: 100%;
		display: block;
	}
}

.burger-alt {
	width: 76px;
	@include aspect-ratio(76/94);
	// cursor: pointer;


	@include tablet {
		
	}
	
	@include mobile {
		width: 38px;
	}

	&__ufo {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
		width: 94.7%;
		height: auto;
		display: block;
		transition: all .3s;

		
		.burger-alt:hover & {
			transform: rotate(-7deg);
		}
	}

	&__light {
		position: absolute;
		bottom: 2px;
		left: 0;
		z-index: 1;
		width: 74%;
		height: auto;
		display: block;
		transition: all .3s;

		
		.burger-alt:hover & {
			transform: rotate(-7deg) translateX(12%);
			transform-origin: 0 50%;
		}
	}
}
