.policy {
	background-color: $c-blue;
	padding-top: 80px;
	padding-bottom: 80px;
	color: $c-purple;

	@include tablet {
		
	}
	
	@include mobile {
		overflow: hidden;
		padding-top: 84px;
	}

	&__container {

	}

	&__title {
		margin-bottom: 80px;

		@include tablet {
			
		}
		
		@include mobile {
			margin-bottom: 30px;
		}
	}

	&__blocks {
	}

	&__row {
		@include row;
		position: relative;
		z-index: 1;

		@include tablet {
			
		}
		
		@include mobile {
			overflow: hidden;
		}

		&+& {
			margin-top: 50px;

			@include tablet {
				
			}
			
			@include mobile {
				margin-top: 1em;
			}
		}

		&--alt {
			flex-direction: row-reverse;
		}
	}

	&__figure {
		@include col(2);

		user-select: none;

		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		.policy__row--alt  &{
			justify-content: flex-start;

			@include tablet {
				
			}
			
			@include mobile {
				transform: translate(29%, -50%);
				left: unset;
				right: 0;
			}
		}

		@include tablet {
			
		}
		
		@include mobile {
			@include col(3, 9);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-29%, -50%);
			z-index: -1;

		}
	}

	&__img {
		max-width: 99%;

		@include tablet {
			
		}
		
		@include mobile {
			max-width: 100%;
		}
	}

	&__content {
		@include col(6);

		@include tablet {
			
		}
		
		@include mobile {
			@include col(8);
		}
	}

	&__text {
		@extend %caps-text;

		@include tablet {
			
		}
		
		@include mobile {
			max-width: 90%;
		}

		h6 {
			text-transform: none;
			margin-bottom: 8px;
			font-size: 12px;
			font-weight: 400;
			line-height: (16/12);
		}
		p {
			
			&+& {
				margin-top: 1em;
			}
		}
		p + p {
			margin-top: 1em;
		}

		&::selection {
			background-color: $c-yellow;
			color: red;
		}
	}
}


.page-border {
	position: fixed;
	top: 50%;
	left: 50%;
	width: calc(100% - 46px);
	height: calc(100% - 34px);
	transform: translate(-50%, -50%);
	background-image: url('../img/border-purple.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	z-index: 2;
	user-select: none;
	pointer-events: none;

	@include mobile {
		background-image: url('../img/border-purple--mobile.png');
		width: calc(100% - 14px);
		height: calc(100% - 14px);
	}
}