.hero {
	position: relative;
	// background-color: $c-purple;
	background-color: #6542bf;
	color: $c-black;
	// height: calc(var(--vh, 1vh) * 120);
	height: calc(var(--vh, 1vh) * 100);
	z-index: 1;
	text-align: center;
	overflow: hidden;
	min-height: 620px;

	@include tablet {
		
	}
	
	@include mobile {
		min-height: 450px;
		overflow: hidden;
	}

	&__container {
		position: relative;
		height: 100%;
		z-index: 3;
		padding-bottom: 12%;

		@include tablet {
			
		}
		
		@include mobile {
			// padding-bottom: 35%;
			padding-bottom: 44%;
		}
	}

	&__main {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding-top: 80px;
		padding-bottom: 60px;

		@media (orientation: landscape) and (max-height: 700px) {
			padding-top: 40px;
			padding-bottom: 30px;
		}
	}

	&__figure {
		position: relative;
		width: 100%;
		margin-top: auto;
		margin-bottom: auto;
		flex-grow: 1;
		user-select: none;
		

		&:hover {
			
		}

		&--static {
			opacity: 0;
			pointer-events: none;
		}

		&--overlay {
			pointer-events: none;
			position: fixed;
			width: 66%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
			pointer-events: none;
			opacity: 0.5;
		}
	}

	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		max-width: 1050px;
		transform: translate(-50%, -50%);
		object-fit: contain;
		transition: transform 1s cubic-bezier(0.34, 1.56, 0.64, 1);

		&:hover {
			transform: translate(-50%, -50%) scale(1.03);
		}
	}

	&__text {
		width: 55%;
		margin-top: 40px;
		margin-bottom: 48px;
		font-size: 20px;
		line-height: 1;
		// letter-spacing: 0.05em;
		opacity: 0;



		@media (orientation: landscape) and (max-height: 700px) {
			width: 70%;
			margin-top: 16px;
			margin-bottom: 32px;
		}

		@include tablet {
			
		}
		@include tablet-v {
			font-size: 18px;
			max-width: 500px;
			width: 100%;
		}
		
		@include mobile {
			// width: 260px;
			// max-width: 500px;
			// width: 100%;
			// max-width: 80%;
			// font-size: 18px;
		}
	}

	&__scroll {
		transform: translateY(200px);
	}

	&__scroll-text {
		// margin-bottom: 48px;
		max-width: 250px;
		font-weight: bold;
		text-transform: uppercase;
		color: $c-yellow;

		@media (orientation: landscape) and (max-height: 700px) {
			// margin-bottom: 32px;
		}

		@include tablet {
			
		}
		
		@include mobile {
			font-size: 16px;
			max-width: 180px;
		}
	}

	&__scroll-btn {
		width: 56px;
		cursor: pointer;
		user-select: none;

		@include mobile {
			position: relative;
			top: -24px;
		}
	}

	&__scroll-icon {
		display: block;
		width: 100%;
		height: auto;
	}

	&__bg {
		position: absolute;
		top: 30%;
		left: 50%;
		display: block;
		width: 100%;
		height: auto;
		z-index: -1;
		user-select: none;
		pointer-events: none;
		transform: translate(-50%, 0);

		@include tablet {
			top: unset;
			bottom: -8%;
		}
		
		@include mobile {
			top: unset;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 30%);
			width: 240vw;
			max-width: unset;
		}
	}
	
	&__border {
		position: absolute;
		top: 50%;
		left: 50%;
		width: calc(100% - 46px);
		height: calc(100% - 34px);
		transform: translate(-50%, -50%);
		background-image: url('../img/hero-border.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		z-index: -1;
		user-select: none;
		opacity: 0;
		pointer-events: none;

		@include mobile {
			background-image: url('../img/hero-border--mobile.png');
			width: calc(100% - 14px);
			height: calc(100% - 14px);
		}
	}

	&__cig {
		position: absolute;
		bottom: 165px;
		right: 45px;
		user-select: none;
		opacity: 0;
		// cursor: pointer;
		pointer-events: none;
		z-index: 3;

		@include mobile {
			display: none;
		}
	}
	&__terrain {
		@include aspect-ratio(1440/544);
		position: absolute;
		bottom: -2vw;
		left: 50%;
		display: block;
		width: 100%;
		opacity: 0;
		transform: translate(-50%, 30%);
		z-index: 2;
		user-select: none;
		image-rendering: pixelated;
		pointer-events: none;

		@include tablet {
			
		}
		
		@include mobile {
			width: 400vw;
			max-width: unset;
			left: 50%;
			transform: translate(-50%, 30%);
			bottom: -5vw;
		}
	}
	&__terrain-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		display: block;
		user-select: none;
		pointer-events: none;
		z-index: 1;
	}


	&__terrain-hero, &__terrain-fire, &__terrain-eyes {
		position: absolute;
		z-index: 2;
	}
	&__terrain-hero {
		top: 66.5%;
		left: 46.8%;
		// filter: invert(1);
		// opacity: 0.5;
		// width: 5.1%;
		width: 5.4%;
		transform: translate(-50%, -50%);
	}

	&__terrain-fire {
		top: 78%;
		left: 36.8%;
		// filter: invert(1);
		// opacity: 0.5;
		// width: 5.1%;
		width: 8%;
		transform: translate(-50%, -50%);

		top: 77.7%;
		left: 8.8%;
		width: 8.7%;
	}

	&__terrain-eyes {
		top: 84.1%;
		left: 49.8%;
		// filter: invert(1);
		// opacity: 0.5;
		// width: 5.1%;
		width: 3.6%;
		transform: translate(-50%, -50%);


		top: 84.1%;
		left: 86.6%;
		width: 3.6%;
	}
}
