%title {
	font-family: $font-pixel;
	font-weight: 700;
	text-transform: uppercase;
}

// pixel title
.title {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: $font-pixel;
	font-weight: normal;
	text-transform: uppercase;
	user-select: none;
	color: inherit;

	margin-top: -6.9%;
	margin-bottom: -3.5%;

	&__svg {
		width: 100%;
		opacity: 0;
		fill: currentColor;

		&.is-ready {
			opacity: 1;
		}
	}
}


%caps-text {
	font-size: 24px;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;

	@include tablet {
		
	}
	
	@include mobile {
		font-size: 16px;
	}
}