html,
body {
	height: 100%;
	font-family: $font-default;
	font-size: 24px;
	line-height: 1;
	font-weight: 400;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: hidden;
	
	@include tablet {
		
	}

	@include mobile {
		font-size: 16px;
	}

	&::-webkit-scrollbar {
		display: none;
		-webkit-appearance: none;
		width: 0;
		height: 0;
	}
}
