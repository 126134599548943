@mixin media($min: null, $max: null) {
	@if $min==null {
		@media (max-width: $max) {
			@content;
		}
	}

	@else if $max==null {
		@media (min-width: $min) {
			@content;
		}
	}

	@else {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
}

@mixin m-down($breakpoint) {
	@include media($max: $breakpoint) {
		@content;
	}
}

@mixin m-up($breakpoint) {
	@include media($min: $breakpoint+1px) {
		@content;
	}
}

@mixin desktopMaxi {
	@include m-up($b-desktop) {
		@content;
	}
}

@mixin laptop {
	@include m-down($b-laptop) {
		@content;
	}
}

@mixin miniLaptop {
	@include m-down($b-labtop-mini) {
		@content;
	}
}

@mixin tabletMaxi {
	@include m-down($b-tablet-maxi) {
		@content;
	}
}

@mixin tablet {
	@include m-down($b-tablet) {
		@content;
	}
}

@mixin tablet-v {
	@include m-down($b-tablet-v) {
		@content;
	}
}

@mixin mobile-up {
	@include m-up($b-mobile) {
		@content;
	}
}

@mixin mobile {
	@include m-down($b-mobile) {
		@content;
	}
}

@mixin mobile-second {
	@media (max-width: $b-mobile-midi) {
		@content;
	}
}

@mixin portrait {
	@media (orientation: portrait) {
		@content;
	}
}

@mixin landscape {
	@media (orientation: landscape) {
		@content;
	}
}

@mixin rtl {
	[dir="rtl"] & {
		@content;
	}
}

