.cookie-bar {
	position: fixed;
	z-index: 1;
	left: 48px;
	bottom: 48px;

	@include tablet {
		
	}
	
	@include mobile {
		bottom: 24px;
		left: 24px;
	}

	&__button {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}

	&__icon {

		&--animal {
			display: block;
			width: 62px;
			height: auto;
			margin-right: 12px;
			transition: all .3s;

			@include tablet {
				
			}
			
			@include mobile {
				width: 48px;
			}

			.cookie-bar__button:hover & {
				transform: scale(1.05) rotate(5deg) translate(17%, 0%);
			}
		}

		&--cookie {
			display: block;
			width: 48px;
			height: auto;
			transition: all .3s;

			@include tablet {
				
			}
			
			@include mobile {
				width: 32px;
			}

			.cookie-bar__button:hover & {
				transform: rotate(-30deg);
			}
		}
	}
}
