// breakpoints (desktop first)
$b-mobile-mini: 479px;
$b-mobile-midi: 639px;
$b-mobile: 700px;
$b-tablet: 1050px;
$b-tablet-v: 850px;
$b-tablet-maxi: 1055px;
$b-labtop-mini: 1299px;
$b-laptop: 1400px;
$b-desktop: 1899px;
$b-desktop-maxi: 2239px;

//fonts

$font-walsheim: "GT Walsheim Trial", helvetica, tahoma, arial, sans-serif;
$font-default: $font-walsheim;
$font-neubit: 'PP NeueBit';
$font-pixel: $font-neubit;

// $font-title: $font-pt-serif;

//colors
$c-black: #000;
$c-white: #fff;
$c-purple: #6542bf;
$c-yellow: #ffcf26;
$c-blue: #5fe1ff;
$c-purple: #c186ff;
$c-orange: #ff9b26;
$c-green: #51d58f;
$c-red: #ff3f33;
$c-cyan: #5fe1ff;

// footer #5daffc


//grid
$grid-columns: 8;
$grid-gutter-width: 48px;

// layout
// $container: 1180px;
// $wrap-lg: 60px;
// $wrap-md: 45px;
// $wrap-sm: 30px;

// tags
// $h: "h1, h2, h3, h4, h5, h6";
// $input-txt: 'input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="time"], input[type="week"], textarea';

// z-index
$z-index: (
	cursor    : 100000000,
	modal     : 1200,
	overlay   : 1100,
	header    : 1000,
	footer    : 900,
	dropdown  : 800
);
